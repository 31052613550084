import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentIndex: 0 // 默认导航到第一个页面
  },
  getters: {
    getCurrentIndex (state) {
      return state.currentIndex
    }
  },
  mutations: {
    setCurrentIndex (state, index) {
      state.currentIndex = index
    }
  },
  actions: {
    setCurrentIndex ({ commit }, index) {
      commit('setCurrentIndex', index)
    }
  },
  modules: {
  }
})
