import Vue from 'vue';
import * as echarts from 'echarts'
import china from 'echarts/map/json/china.json';

import taiwan from 'echarts/map/json/province/taiwan.json';
import hebei from 'echarts/map/json/province/hebei.json';
import shanxi from 'echarts/map/json/province/shanxi.json';
import liaoning from 'echarts/map/json/province/liaoning.json';
import jilin from 'echarts/map/json/province/jilin.json';
import heilongjiang from 'echarts/map/json/province/heilongjiang.json';
import jiangsu from 'echarts/map/json/province/jiangsu.json';
import zhejiang from 'echarts/map/json/province/zhejiang.json';
import anhui from 'echarts/map/json/province/anhui.json';
import fujian from 'echarts/map/json/province/fujian.json';
import jiangxi from 'echarts/map/json/province/jiangxi.json';
import shandong from 'echarts/map/json/province/shandong.json';
import henan from 'echarts/map/json/province/henan.json';
import hubei from 'echarts/map/json/province/hubei.json';
import hunan from 'echarts/map/json/province/hunan.json';
import guangdong from 'echarts/map/json/province/guangdong.json';
import hainan from 'echarts/map/json/province/hainan.json';
import sichuan from 'echarts/map/json/province/sichuan.json';
import guizhou from 'echarts/map/json/province/guizhou.json';
import yunnan from 'echarts/map/json/province/yunnan.json';
import shanxi1 from 'echarts/map/json/province/shanxi1.json';
import gansu from 'echarts/map/json/province/gansu.json';
import qinghai from 'echarts/map/json/province/qinghai.json';
import xinjiang from 'echarts/map/json/province/xinjiang.json';
import guangxi from 'echarts/map/json/province/guangxi.json';
import neimenggu from 'echarts/map/json/province/neimenggu.json';
import ningxia from 'echarts/map/json/province/ningxia.json';
import xizang from 'echarts/map/json/province/xizang.json';
import beijing from 'echarts/map/json/province/beijing.json';
import tianjin from 'echarts/map/json/province/tianjin.json';
import shanghai from 'echarts/map/json/province/shanghai.json';
import chongqing from 'echarts/map/json/province/chongqing.json';
import xianggang from 'echarts/map/json/province/xianggang.json';
import aomen from 'echarts/map/json/province/aomen.json';
echarts.registerMap('china', china)
echarts.registerMap('taiwan', taiwan)
echarts.registerMap('hebei', hebei)
echarts.registerMap('shanxi', shanxi)
echarts.registerMap('liaoning', liaoning)
echarts.registerMap('jilin', jilin)
echarts.registerMap('heilongjiang', heilongjiang)
echarts.registerMap('jiangsu', jiangsu)
echarts.registerMap('zhejiang', zhejiang)
echarts.registerMap('anhui', anhui)
echarts.registerMap('fujian', fujian)
echarts.registerMap('jiangxi', jiangxi)
echarts.registerMap('shandong', shandong)
echarts.registerMap('henan', henan)
echarts.registerMap('hubei', hubei)
echarts.registerMap('hunan', hunan)
echarts.registerMap('guangdong', guangdong)
echarts.registerMap('hainan', hainan)
echarts.registerMap('sichuan', sichuan)
echarts.registerMap('guizhou', guizhou)
echarts.registerMap('yunnan', yunnan)
echarts.registerMap('shanxi1', shanxi1)
echarts.registerMap('gansu', gansu)
echarts.registerMap('qinghai', qinghai)
echarts.registerMap('xinjiang', xinjiang)
echarts.registerMap('guangxi', guangxi)
echarts.registerMap('neimenggu', neimenggu)
echarts.registerMap('ningxia', ningxia)
echarts.registerMap('xizang', xizang)
echarts.registerMap('beijing', beijing)
echarts.registerMap('tianjin', tianjin)
echarts.registerMap('shanghai', shanghai)
echarts.registerMap('chongqing', chongqing)
echarts.registerMap('xianggang', xianggang)
echarts.registerMap('aomen', aomen)

Vue.prototype.$echarts = echarts;

export const provinceData = {
  china: china,
  taiwan: taiwan,
  hebei: hebei,
  shanxi: shanxi,
  liaoning: liaoning,
  jilin: jilin,
  heilongjiang: heilongjiang,
  jiangsu: jiangsu,
  zhejiang: zhejiang,
  anhui: anhui,
  fujian: fujian,
  jiangxi: jiangxi,
  shandong: shandong,
  henan: henan,
  hubei: hubei,
  hunan: hunan,
  guangdong: guangdong,
  hainan: hainan,
  sichuan: sichuan,
  guizhou: guizhou,
  yunnan: yunnan,
  shanxi1: shanxi1,
  gansu: gansu,
  qinghai: qinghai,
  xinjiang: xinjiang,
  guangxi: guangxi,
  neimenggu: neimenggu,
  ningxia: ningxia,
  xizang: xizang,
  beijing: beijing,
  tianjin: tianjin,
  shanghai: shanghai,
  chongqing: chongqing,
  xianggang: xianggang,
  aomen: aomen
}